import React from 'react'

class SelectedBar extends React.Component {
    render() {
        let { prevCond, selected } = this.props

        let _flts = [],
            trip = ['一', '二', '三', '四', '五', '六'],
            flightSegments = selected.getIn(['flights', 'flightSegments']),
            selectedLength = flightSegments ? flightSegments.size : 0,
            i = 0
        if (prevCond.get('flightWay') == 'M') {
            for (; i < prevCond.get('flightSegments').size; i++) {
                if (i <= selectedLength) {
                    _flts.push({ selected: true, txt: `选择第${trip[i]}程${i == prevCond.get('flightSegments').size - 1 ? '（预订）' : ''}` })
                }
                else {
                    _flts.push({ selected: false, txt: `选择第${trip[i]}程${i == prevCond.get('flightSegments').size - 1 ? '（预订）' : ''}` })
                }
            }

        }

        return (
            _flts.length > 0 ?
                <div className="flt-selected-bar">
                    {
                        _flts.map((v, k) =>

                            <span key={k} style={{ zIndex: 10 - k }} className={"flt-bar" + (v.selected ? ' selected' : '')}>
                                <i className="border-diagonal"></i>
                                <i className="ico-checkbox-c"></i>{v.txt}
                            </span>
                        )
                    }
                </div> : null
        )
    }
}

export default SelectedBar
