import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import FlightSummary from '../flightSummary/flightSummary';
import SelectedBar from './selectedBar';


import Converter from '../../../sources/list/converter';
import { BatchGetVisas, FlightSummaryFragment } from '../common/visaFragment';

import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../ubt.v2/list';
import { getIfScopeDomestic } from '../../../sources/list/scope';

import { onRouteSearch } from '../../../actions/list/flight';

const SelectedFlightsV1 = props => {
  let {
    prevCond,
    selected,
    comfortTags,
    globalSwitch,
    onPopupFlightDetail,
    comfortPopupIndex,
    setComfortPopupIndex,
    reSelect,
    searchCriteriaToken,
    currentSegmentSeq,
  } = props;
  let selectedFlights = selected.get('flights');
  return (
    <div className="flt-selected">
      <SelectedBar {...props} />

      <CSSTransitionGroup transitionName="selected-flights" transitionEnterTimeout={3000} transitionLeaveTimeout={2000}>
        {selectedFlights ? (
          <div key="selected-flights" className="flt-selected-box">
            <div className="side-title">{prevCond.get('flightWay') == 'M' ? '已选航程' : '已选去程'}</div>
            <FlightSummary
              prevCond={prevCond}
              globalSwitch={globalSwitch}
              flights={selected.get('flights')}
              comfortTags={comfortTags}
              reSelect={reSelect}
              isShowSegmentNo={prevCond.get('flightWay') == 'M'}
              hiddenMultiPU={true}
              changeComfortPopupIndex={setComfortPopupIndex}
              comfortPopupIndex={comfortPopupIndex}
              onPopupFlightDetail={onPopupFlightDetail}
              searchCriteriaToken={searchCriteriaToken}
              currentSegmentSeq={currentSegmentSeq}
            />
          </div>
        ) : null}
      </CSSTransitionGroup>
    </div>
  );
};

// 为往返tab定制的已选航班
const SelectedFlightsV2 = props => {
  let { prevCond, selected, reSelect, activeRoundTabIndex, showTongChengRemark, onPopupFlightDetail } = props;
  let selectedFlights = selected.get('flights');

  const firstSegment = selected.getIn(['flights', 'flightSegments', 0]);
  const transferAndStopCount = firstSegment.get('transferAndStopCount');

  const mainFlightItem = getIfScopeDomestic()
    ? firstSegment.getIn(['flightList', 0])
    : firstSegment.getIn(['flightList']).find(item => item.get('mainSegment'));
  const marketAirlineCode = mainFlightItem.getIn(['marketAirlineCode']);
  const marketAirlineName = mainFlightItem.getIn(['marketAirlineName']);
  const mainOperateAirlineCode = mainFlightItem.get('operateAirlineCode');
  const mainOperateAirlineName = mainFlightItem.get('operateAirlineName');
  const mainFlightItemIsSharedAirline = getIfScopeDomestic() ? mainOperateAirlineCode : mainOperateAirlineName;
  const nonMainFlightItemIsSharedAirline = !!firstSegment
    .getIn(['flightList'])
    .some(item => item !== mainFlightItem && (getIfScopeDomestic() && item.getIn(['operateAirlineCode']) || !getIfScopeDomestic() && item.getIn(['operateAirlineName'])));
  const hasManyAirlineCode = getIfScopeDomestic()
    ? transferAndStopCount > 0
    : transferAndStopCount > 0 &&
      new Set(
        firstSegment
          .getIn(['flightList'])
          .map(item => item.get('marketAirlineCode'))
          .toArray(),
      ).size > 1;

  const departureCityName = prevCond.getIn(['flightSegments', 0, 'departureCityName']);
  const departureAirportShortName = firstSegment.getIn(['departureAirportShortName'], '');
  const departureTerminal = firstSegment.getIn(['departureTerminal'], '');

  const arrivalCityName = prevCond.getIn(['flightSegments', 0, 'arrivalCityName']);
  const arrivalAirportShortName = firstSegment.getIn(['arrivalAirportShortName'], '');
  const arrivalTerminal = firstSegment.getIn(['arrivalTerminal'], '');

  const departureDateTime = firstSegment.getIn(['departureDateTime']);
  const departureDate = Converter.extractDateRemoveYear(departureDateTime);
  const departDateOfFormated = Converter.remainMonthAndDay(departureDate, 'MM-DD');
  const departDateWeekday = Converter.remianWeek(departureDateTime);

  const departureTime = Converter.extractTime(departureDateTime);
  const arrivalDateTime = firstSegment.getIn(['arrivalDateTime']);
  const arrivalTime = Converter.extractTime(arrivalDateTime);

  const duration = firstSegment.getIn(['duration']);
  const durationInDayHourMinute = Converter.formatMinAsDayHourMin(duration, 'DDdHHhMMm');
  const crossDays = firstSegment.getIn(['crossDays']);

  const priceList = selectedFlights.get('priceList'),
    cleanItineraryId = selectedFlights.get('itineraryId').replace(/[,\|]/gi, ''),
    tongChengFlightEnum = selectedFlights.get('tongChengFlightEnum');

  const flightInfos = [
    {
      tongChengFlightEnum,
      segment: firstSegment,
      currentSegmentSeq: 0,
      cleanItineraryId,
      priceList,
      showTongChengRemark: showTongChengRemark,
    },
  ];
  const selectedFlightDetailFragement = FlightSummaryFragment({
    onFlightDetailClick: () => {
      BatchGetVisas({
        flightDetailInfos: flightInfos,
        onPopupFlightDetail: onPopupFlightDetail,
        currentSegmentSeq: 0,
      });
    },
  });
  return (
    <CSSTransitionGroup
      transitionName="selected-flights"
      transitionEnterTimeout={300}
      transitionAppearTimeout={300}
      transitionAppear={true}
      transitionLeave={false}
    >
      {selectedFlights && activeRoundTabIndex ? (
        <div className="segment_tab_selected_flight">
          <span>
            已选去程：{departureCityName}
            {departureAirportShortName}
            {departureTerminal}
          </span>
          <span className="round_tab_arrow" />
          <span>
            {arrivalCityName}
            {arrivalAirportShortName}
            {arrivalTerminal}
          </span>
          <span>
            {' '}
            {departDateOfFormated} {departDateWeekday} {departureTime} - {arrivalTime}
          </span>
          {crossDays ? <span className="cross_days">+{crossDays}</span> : null}
          <span className="duration">{durationInDayHourMinute}</span>
          <span className="trans_count">{transferAndStopCount === 0 ? '直飞' : `中转${transferAndStopCount}次`}</span>

          <span className="split_line" />
          <img
            className="airline_logo"
            src={`//pic.c-ctrip.com/flight_intl/airline_logo/32x32/${marketAirlineCode}.png`}
            alt=""
          />
          <span className="airline_name">
            {marketAirlineName}
            {(hasManyAirlineCode
            ? mainFlightItemIsSharedAirline
            : mainFlightItemIsSharedAirline || nonMainFlightItemIsSharedAirline)
              ? '(共享)'
              : ''}
            {hasManyAirlineCode ? `...等${nonMainFlightItemIsSharedAirline ? '(共享)' : ''}` : ''}
          </span>

          <span className="high_light_link flight_detail_link">{selectedFlightDetailFragement}</span>
          <UbtBoundary
            tracker={ListUbtGenerator(
              LIST_UBT_GROUP_TYPES.TRIGGER_NOW_CLICK,
              LIST_UBT_KEY_TYPES.TRIGGER_NOW_CLICK,
              'c_round_tab_click_modify_depart',
            )}
          >
            <span className="high_light_link research_link" onClick={() => reSelect(0)}>
              修改去程
            </span>
          </UbtBoundary>
        </div>
      ) : null}
    </CSSTransitionGroup>
  );
};

const SelectedFlights = ({ ...props }) => {
  const { searchNoResultRecommend } = props;
  if (!searchNoResultRecommend) {
    let { selected, searchFlights, onSetActiveRoundTabIndex, roundTabModeSwitch } = props,
      selectedFlights = selected.get('flights');

    const [comfortPopupIndex, setComfortPopupIndex] = useState(0);

    const reSelect = index => {
      const { roundTabModeSwitch } = props;
      if (roundTabModeSwitch) {
        onSetActiveRoundTabIndex(index);
      } else {
        let { selected, prevCond, isBuildUpMode, classGradeFilterEnum } = props,
          selectedflight = null,
          routeSearchCriteria = selected.getIn(['routeSearchCriteria', index]);
        searchFlights(selectedflight, routeSearchCriteria, prevCond, index, isBuildUpMode, classGradeFilterEnum);
      }
    };

    return selectedFlights && selected.get('flights').size ? (
      roundTabModeSwitch ? (
        <SelectedFlightsV2
          {...props}
          comfortPopupIndex={comfortPopupIndex}
          setComfortPopupIndex={setComfortPopupIndex}
          reSelect={reSelect}
        />
      ) : (
        <SelectedFlightsV1
          {...props}
          comfortPopupIndex={comfortPopupIndex}
          setComfortPopupIndex={setComfortPopupIndex}
          reSelect={reSelect}
        />
      )
    ) : null;
  } else {
    return null;
  }
};

export default connect(null, dispatch => ({
  searchFlights: (
    selectedflight,
    routeSearchCriteria,
    prevCond,
    targetSegmentSeq,
    isBuildUpMode,
    classGradeFilterEnum,
  ) => {
    dispatch(
      onRouteSearch(
        selectedflight,
        routeSearchCriteria,
        prevCond,
        targetSegmentSeq,
        isBuildUpMode,
        classGradeFilterEnum,
      ),
    );
  },
}))(SelectedFlights);
